/* AppVideos.css */

  .fixedButtonContainer {
    position: fixed;
    top: calc(50% - 60px);
    left: 100px; /* 기본값 */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.7s ease;
    pointer-events: none;
  }

  @media (max-height: 1115px) {
    .fixedButtonContainer {
      top: 520px;
    }
  }

  @media (max-width: 800px) {
    .fixedButtonContainer {
      left: 6.25vw;
      top: calc(50% - 30px);
    }
  }

  .fixedButtonContainer.show {
    opacity: 1;
    pointer-events: auto;
  }
  
  .arrowButton {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .videoButton {
    cursor: pointer;
    width: 100px;
    height: 100px;
  }