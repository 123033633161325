/* src/components/SNSButton.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    position: relative;
    width: 90%;
    max-width: 400px;
    overflow: hidden;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .closeButtonImage {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  
  .switchImage {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 130px;
    height: 130px;
    opacity: 0;
    transition: opacity 0.7s ease;
    pointer-events: none;
  }

  .switchImage.show {
    opacity: 1;
    pointer-events: auto;
  }
  
  .imageOverlay {
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
    width: 100px;
    height: 100px;
    pointer-events: none;
  }

  .imageOverlay.show {
    opacity: 1;
    pointer-events: auto;
  }
  
