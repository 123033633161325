.page5 {
    display: flex;
    background-color: white;
    justify-content: center;
    background-size: cover;
    background-position: center;
  }

  .container_div_5 {
    width: 1024px; /* 너비를 1024px로 설정 */
    padding: 130px 50px;
    height: '100%';
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .item_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0px 100px;
  }

  .auto-image-slider {
    position: relative;
    width: 300px;
    height: 200px;
    overflow: hidden;
  }

  .random_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    visibility: hidden; /* 투명도와 함께 사용하여 숨김 처리 */
  }

  .visible {
    opacity: 1;
    visibility: visible;
  }
  
  .hidden {
    opacity: 0;
    visibility: hidden;
  }

  .footer_text_img {
    width: 300px;
    height: 200px;
  }

  footer a {
    color: rgb(51,51,51);
  }

  footer a span {
    font-size: 14px;
    color: rgb(51,51,51);
    line-height: 1.5;
  }

  .highlight {
    background-color: yellow;
  }
  
  footer p {
    font-size: 14px;
    color: rgb(51,51,51);
    line-height: 1.5;
  }
  
  footer p span {
    font-size: 14px;
    color: rgb(51,51,51);
    line-height: 1.5;
  }
  
  @media (max-width: 800px) {
    .container_div_5 {
      padding: 16.25vw 6.25vw 6.25vw 6.25vw;
    }
  }

