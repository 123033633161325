.page_5 {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .background_cover_div_5 {
    position: absolute;
    top: -100%;
    left: -100%;
    right: -100%;
    bottom: -100%;
    background: linear-gradient(to right bottom, #379237 0% , #379237 25%, #F0FF42 75%, #F0FF42 100%);
    z-index: -2
  }

  /* page2 */
  /* 홀,odd: 시계 */
  /* 짝,oeven: 반시계 */

  .odd_5 {
    transform: rotate(45deg);
    transform-origin: center center;
  }

  .even_5 {
    transform: rotate(-45deg);
    transform-origin: center center;
  }

  .centered_div_5 {
    width: 1024px; 
    padding: 180px 50px 0px;
    height: '100%';
    box-sizing: border-box;
    position: relative;
  }

  .text_img_5 {
    width: 400px;
    height: 200px;
  }

  .text_div_5 p {
    margin: 0px; 
    padding: 0px;
    color: white;
    font-size: 18px;
  }

  .animation_img_5 {
    position: absolute; 
    bottom: 0;
    right: 0;
    width: 768px;
    height: 768px;
    opacity: 0;
    transform: translateX(200px);
    transition: transform 1s ease, opacity 1s ease;
  }

  .animation_img_5_active {
    transform: translateX(0);
    opacity: 0.8;
  }
  
  @media (max-width: 800px) {
    .centered_div_5 {
      padding: 22.5vw 6.25vw 0 6.25vw;
    }
  }

  @media (max-width: 625px) {
    .text_img_5 {
      width: 64vw;
      height: 32vw;
    }
    .text_div_5 p {
      font-size: 16px;
    }
  }

  @media (max-height: 1448px) {
    .animation_img_5 {
      width: 53vh;
      height: 53vh;
    }
  }

  @media (max-width: 480px) {
    .animation_img_5 {
      width: 100vw;
      height: 100vw;
      bottom: -35px;
    }
    .text_div_5 p {
      font-size: 14px;
    }
  }
