.fixed-header {
    position: fixed;
    top: 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.7s ease;
    pointer-events:auto;
  }

  .fixed-header-hide {
    opacity: 0;
    pointer-events:none;
  }

  .header-right-box {
    display: flex;
    flex-direction: row;
  }

  .header_name_img {
    width: 150px;
    height: 50px;
  }

  .download-button {
    margin-left: 20px;    
    width: 150px;
    height: 50px;
  }
  
  @media (min-width: 1024px) {
    .fixed-header {
      left: 50%; 
      transform: translateX(-50%);
      width: 1024px;
      padding: 50px 50px 0px 50px;
    }
  }
  
  @media (max-width: 1024px) {
    .fixed-header {
      width: 100%;
      padding: 50px 50px 0px 50px;
    }
  }

  @media (max-width: 800px) {
    .fixed-header {
      padding: 6.25vw 6.25vw 0 6.25vw;
    }
  }

  @media (max-width: 625px) {
    .header_name_img {
      width: 24vw;
      height: 8vw;
    }
    .download-button {
      width: 24vw;
      height: 8vw;
    }
  }

