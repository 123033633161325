/* Dots.css */

.dots-container {
    position: fixed;
    top: calc(50% - 60px);
    right: 100px; /* 기본값 */
    opacity: 0;
    transition: opacity 0.7s ease;
    pointer-events: none;
  }
  
  @media (max-width: 800px) {
    .dots-container {
      right: 6.25vw;
    }
  }
  
  .dots-container.show {
    opacity: 1;
    pointer-events: none;
  }
  
  .dots-container > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 20px;
    height: 120px;
  }
  
  .dots-container > div > div {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: url('/public/images/dot.png');
    background-position: center center; 
    background-size: cover;
  }

  .dots-container > div > div.dot_active {
    background-image: url('/public/images/dot_active.png'); 
  }
  