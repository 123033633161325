/* src/components/SNSButton.css */
.sns-button-container {
    position: relative;
  }
  
  .sns-main-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .sns-icons-container {
    position: absolute;
    top: 65px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  @media (max-width: 625px) {
    .sns-icons-container {
      top: 45px;
    }
  }
  
  .sns-icon {
    width: 50px;
    height: 50px;
  }
  
  .sns-icons-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .sns-icons-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .sns-icons-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .sns-icons-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  