.page1 {
    display: flex;
    background-image: url('/public/images/landding_bg_img.png');
    background-size: cover; /* 뷰포트에 이미지 맞춤 */
    background-position: center center;
    justify-content: center;
  }

  .centered-div {
    width: 1024px; /* 너비를 1024px로 설정 */
    padding: 180px 50px 0px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .text-img1 {
    width: 600px;
    height: 600px;
    transform: translate(-100px, -100px);
  }


  @media screen and (max-width: 600px) {
    .page1 {
      background-image: url('/public/images/mobile-background.jpg');
    }
  }

  @media (max-width: 800px) {
    .centered-div {
      padding: 22.5vw 6.25vw 0 6.25vw;
    }
  }

  @media (max-width: 750px) {
    .text-img1 {
      width: 80vw;
      height: 80vw;
      transform: translate(-13.3vw, -13.3vw);
    }
  }

  .qr_code_box {
    position: absolute; 
    top: 530px;
    right: 0;
    width: 200px;
    padding-right: 50px;
  }

  .qr_code {
    width: 200px;
    height: 200px;
  }

  .qr_code_text {
    display: block;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 800;
    line-height: 1.38;
    margin-top: 0;
  }
  
  @media (max-height: 1000px) {
    .qr_code {
      width: 20vh;
      height: 20vh;
    }
    .qr_code_box {
      width: 20vh;
    }
  }


  @media (max-width: 800px) {
    .qr_code {
      display: none;
    }
    .qr_code_text {
      display: none;
    }
  }
